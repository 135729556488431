@keyframes iconLoading {
    0% { opacity: 1;}
    50% { opacity: 0;}
    100% { opacity: 1;}
}

@-o-keyframes iconLoading {
    0% { opacity: 1;}
    50% { opacity: 0;}
    100% { opacity: 1;}
}

@-moz-keyframes iconLoading {
    0% { opacity: 1;}
    50% { opacity: 0;}
    100% { opacity: 1;}
}

@-webkit-keyframes iconLoading {
    0% { opacity: 1;}
    50% { opacity: 0;}
    100% { opacity: 1;}
}

.iconloading {
    padding-left: 42vw;
    padding-top: 30vh;
    -webkit-animation: iconLoading 3s infinite;
    -moz-animation: iconLoading 3s infinite;
    -o-animation: iconLoading 3s infinite;
    animation: iconLoading 3s infinite;
}

.iconContainer {
    height: 100vh;
    width: 100vw;
    background-color: white;
    z-index: 100;

}

