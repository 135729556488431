.helperIcon:hover{
    border-radius: 3px;
    background-color: lightgrey;
    padding-left: 2px;
    padding-top: 2px;
    padding-right: 2px;
}

.helperIcon{
    padding-left: 2px;
    padding-top: 2px;
    padding-right: 2px;
}

