div::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  
  div::-webkit-scrollbar-track {
    background: rgba(96,16,105,0.2);        /* color of the tracking area */
  }
  
  div::-webkit-scrollbar-thumb {
    background-color: rgb(96,16,105);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid rgba(96,16,105,0.2);  /* creates padding around scroll thumb */
  }