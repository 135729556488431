.manage {
    margin: 10px 0px 0px 10px;
    /* border-top: 2px solid lightgrey;  */
    padding-bottom: 10px;
    width: 91%;
    padding-top: 10px;
}
.manage:hover {
    cursor: pointer;
    background-color: lightgray;
}