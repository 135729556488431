.timeline-container{
  display: flex;
  min-height: 200px;
  width: 50vw;
  padding-left: 20px;
}

.timeline-container:last-child{
  padding-bottom: 30px;
}

.timeline-date{
  font-weight: bold;
  width: 140px;
  line-height: 200px;
  padding-right: 10px;
}

.timeline-line{
  min-height: 200px;
  border-right: 3px dashed grey;
}

.timeline-lineh{
  width: 20px;
  height: 100px;
  border-bottom: 3px dashed grey;
}

.timeline-level{
  font-weight: bold;
  padding-left: 10px;
}

.timeline-details{
  border-left: 6px solid orange;
  width: 500px;
  word-wrap: break-word;
  margin-top: 90px;
}

.timeline-info{
  padding-top: 10px;
  padding-left: 20px;
  width: 35vw;
  white-space: normal;

}