.statusDrop:hover{
    cursor: pointer;
    background-color: rgb(243, 242, 241);
    padding-left: 45%;
    color: lightgrey;
    border-bottom: 1px solid rgb(237, 235, 233);
    padding-top: 2px;
}

.statusDrop{
    padding-top: 2px;
    padding-left: 45%;
    color: lightgrey;
    border-bottom: 1px solid rgb(237, 235, 233);
}